import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/CRUD')),
};

const Feature = {
	ParameterChecklist: lazy(() => import('../views/ParameterChecklist')),
	TemplateSelection: lazy(() => import('../views/MyTemplateSelection')),
	ChecklistApproval: lazy(() => import('../views/ChecklistApproval')),
	Assignment: lazy(() => import('../views/Assignment')),
	ApprovalFLow: lazy(() => import('../views/ApprovalFlow')),
	Generated: lazy(() => import('../views/eko/checklist/MyGenerated')),
	OneTimeScript: lazy(() => import('../views/OneTimeScript')),
	Location: lazy(() => import('../views/Location')),
};

const Approval = {
	Approval: lazy(() => import('../views/eko/submit_approval/MyApproval')),
	// Approval: lazy(() => import('../views/bakti/MyApproval')),
	DraftApproval: lazy(() => import('../views/MyDraftApproval')),
};

const List = {
	GroupingShift: lazy(() => import('../views/GroupingShift')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <Feature.ParameterChecklist />,
		index: 'ParameterChecklist',
		exact: true,
	},
	{
		path: null,
		element: <Feature.TemplateSelection />,
		index: 'TemplateSelection',
	},
	{
		path: null,
		element: <Feature.ChecklistApproval />,
		index: 'ChecklistApproval',
		exact: true,
	},
	{
		path: null,
		element: <Feature.Assignment />,
		index: 'Assignment',
		exact: true,
	},
	{
		path: null,
		element: <Feature.ApprovalFLow />,
		index: 'ApprovalFlow',
		exact: true,
	},
	{
		path: null,
		element: <Feature.Generated />,
		index: 'Generated',
		exact: true,
	},
	{
		path: null,
		element: <Feature.OneTimeScript />,
		index: 'OneTimeScript',
		exact: true,
	},
	{
		path: null,
		element: <Feature.Location />,
		index: 'Location',
		exact: true,
	},
	{
		path: null,
		element: <Approval.Approval />,
		index: 'Approval',
		exact: true,
	},
	{
		path: null,
		element: <Approval.DraftApproval />,
		index: 'DraftApproval',
		exact: true,
	},
	{
		path: null,
		element: <List.GroupingShift />,
		index: 'GroupingShift',
		exact: true,
	},
];
export default contents;
